export default function EditIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 19H19M1.00003 19H2.67457C3.16376 19 3.40835 19 3.63852 18.9447C3.84259 18.8957 4.03768 18.8149 4.21663 18.7053C4.41846 18.5816 4.59141 18.4086 4.93732 18.0627L17.5001 5.49998C18.3285 4.67156 18.3285 3.32841 17.5001 2.49998C16.6716 1.67156 15.3285 1.67156 14.5001 2.49998L1.93729 15.0627C1.59139 15.4086 1.41843 15.5816 1.29475 15.7834C1.18509 15.9624 1.10428 16.1574 1.05529 16.3615C1.00003 16.5917 1.00003 16.8363 1.00003 17.3255V19Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
