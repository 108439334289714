import Head from "next/head";
import { useRef, useState } from "react";
import { useSession } from "next-auth/react";
import Section from "../components/styles/section";
import PageHeading from "../components/page-heading";
import BasicTable from "../components/basic-table";
import LinkIcon from "../components/icons/link";
import PlusIcon from "../components/icons/plus";
import { createColumnHelper } from "@tanstack/react-table";
import format from "date-fns/format";
import useSWR from "swr";
import { dasherize } from "../utils/strings";
import { toast } from "react-toastify";
import Link from "next/link";
import DownloadIcon from "../components/icons/download";
import { CSVLink } from "react-csv";
import TrashIcon from "../components/icons/trash";
import EditIcon from "../components/icons/edit";
import * as fathom from "fathom-client";
Home.auth = true;

function convertToCSV(arr) {
  const array = [Object.keys(arr[0])].concat(arr);

  return array
    .map((it) => {
      return Object.values(it).toString();
    })
    .join("\n");
}

export default function Home() {
  const { data: session } = useSession();
  const [subscribers, setSubscribers] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const { data: links, error, mutate: mutateLinks } = useSWR("/api/links");
  const membershipType = session?.user?.membershipType;
  const canAddLinks =
    (membershipType === "FREE" && links?.length < 3) ||
    (membershipType === "PRO" && links?.length < 100);
  console.log("membershipType", membershipType);

  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  const getStatusCellClassNames = (status) => {
    let classNames;
    switch (status) {
      case "DRAFT":
        classNames = "bg-blue-50 text-blue-700";
        break;
      case "ARCHIVED":
        classNames = "bg-amber-50 text-amber-700";
        break;
      case "ACTIVE":
        classNames = "bg-green-50 text-green-700";
        break;
      default:
        break;
    }
    return classNames;
  };

  const columnHelper = createColumnHelper();

  const defaultColumns = [
    columnHelper.accessor("title", {
      header: () => "Name",
      cell: (info) => {
        const linkImageUrl = info.row.original.imageUrl;
        return (
          <Link
            href={`/links/${info.row.original.id}/edit`}
            className="flex items-center"
          >
            {/* {linkImageUrl ? (
              <div className="relative overflow-hidden bg-accent100 text-accent rounded-full mr-3 w-9 h-9">
                <img
                  src={linkImageUrl}
                  alt=""
                  className="w-full h-full absolute top-0 left-0 object-cover"
                />
              </div>
            ) : (
            )} */}
            <div className="relative overflow-hidden bg-accent100 text-accent p-2 rounded-full mr-3">
              <LinkIcon />
            </div>
            <span className="font-medium text-primary text-sm">
              {info.getValue()}
            </span>
          </Link>
        );
      },
    }),
    columnHelper.accessor("signupUrl", {
      header: () => "URL",
      cell: (info) => {
        const url = `${window?.location?.origin}/${dasherize(info.getValue())}`;
        const copyUrl = () => {
          navigator.clipboard.writeText(url);
          fathom.trackGoal("C2FZGGZZ", 0);
          toast.success("Copied URL to clipboard");
        };
        return (
          <button
            onClick={copyUrl}
            className="inline-block bg-gray-100 text-gray-700 px-2 text-xs font-medium rounded-full leading-snug"
          >
            /{dasherize(info.getValue())}
          </button>
        );
      },
    }),
    columnHelper.accessor("status", {
      header: () => "Status",
      cell: (info) => (
        <span
          className={`${getStatusCellClassNames(
            info.getValue()
          )} inline-block leading-loose px-2 text-xs font-medium rounded-full capitalize`}
        >
          {info.getValue().toLowerCase()}
        </span>
      ),
    }),
    columnHelper.accessor("signups", {
      header: () => "Signups",
      cell: (info) => {
        const count = info.row.original?._count?.subscribers;
        return <span className="text-sm text-default">{count}</span>;
      },
    }),
    columnHelper.accessor("updatedAt", {
      header: () => "Last Modified",
      cell: (info) => (
        <span className="text-sm text-default leading-none">
          {format(new Date(info.getValue()), "PP")}
        </span>
      ),
    }),
    columnHelper.display({
      id: "export",
      cell: (props) => {
        const { id, title, signupUrl } = props.row.original;

        const getData = async () => {
          try {
            setExportLoading(true);
            const res = await fetch(`/api/subscribers?linkId=${id}`);
            const data = await res.json();
            console.log(data);
            const modifiedData = data
              .filter((signup) => signup.verified)
              .map((signup) => {
                return { email: signup.email, signupLink: signupUrl };
              });
            console.log(modifiedData);
            const csv = convertToCSV(modifiedData);
            setSubscribers(csv);

            setTimeout(() => {
              csvLink.current.link.click();
              setExportLoading(false);
              fathom.trackGoal("1QY7ZTSB", 0);
            }, 2000);
          } catch (error) {
            console.log(error);
            return;
          }
        };

        return (
          <span>
            <button
              onClick={getData}
              className="font-semibold text-sm mr-3 text-default flex shrink-0 items-center rounded-lg"
            >
              <div className="mr-3">
                <DownloadIcon />
              </div>
              {exportLoading ? "Loading" : "Export"}
            </button>
            <CSVLink
              data={subscribers}
              className="hidden"
              ref={csvLink}
              target="_blank"
              filename={`subscribers-by-link--${dasherize(title)}.csv`}
            />
          </span>
        );
      },
    }),
    columnHelper.display({
      id: "actions",
      cell: (props) => {
        const { id: linkId } = props.row.original;

        return (
          <div className="flex gap-4">
            <button
              className="font-semibold text-sm mr-3 text-default"
              onClick={() => deleteLink(linkId)}
            >
              <TrashIcon className="w-4 h-4" />
            </button>
            <Link
              href={`/links/${linkId}/edit`}
              className="font-semibold text-sm text-default"
            >
              <EditIcon className="w-4 h-4" />
            </Link>
          </div>
        );
      },
    }),
  ];

  const deleteLink = async (id) => {
    try {
      const res = await fetch(`/api/links/${id}`, {
        method: "DELETE",
      });
      const data = await res.json();
      console.log(data);
      await mutateLinks();
      fathom.trackGoal("RR48DHE0", 0);
      toast.success(
        "Link archived - your emails are still saved to the main list"
      );
    } catch (error) {
      console.error(error);
      toast.error("Error deleting link");
    }
  };

  if (!links) "loading";
  if (!error) "error";

  return (
    <div>
      <Head>
        <title>My Links - Gatelinks</title>
        <meta name="description" content="Give Link, Get Email" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Section withContainer classes="mt-8">
        <PageHeading
          heading="Dashboard"
          description="Documents and attachments that have been uploaded as part of this project."
          button={
            canAddLinks
              ? {
                  icon: <PlusIcon />,
                  text: "New Link",
                  link: "/create-link",
                }
              : {
                  icon: <PlusIcon />,
                  text: "Upgrade to Add More Links",
                  link: "/account/plan",
                }
          }
        />
        <BasicTable columnData={defaultColumns} rowData={links} />
      </Section>
    </div>
  );
}
